<div class="container">
  <div class="header h3 mb-4 d-flex">
    <span class="flex-grow-1">{{ "careplan.CareTeam" | translate }}</span>
    <ng-container *ngIf="authService.hasRole('ROLE_CARE_COORDINATOR')">
      <button pButton (click)="addPractitionerToCareTeam()" [label]="'Add to Care Team' | translate"></button>
    </ng-container>
  </div>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ "Name" | translate }}</th>
          <th scope="col">{{ "Specialty" | translate }}</th>
          <th scope="col">{{ "Phone" | translate }}</th>
          <th scope="col">{{ "Email" | translate }}</th>
          <ng-container *ngIf="authService.hasRole('ROLE_CARE_COORDINATOR')">
            <th scope="col">{{ "Actions" | translate }}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doctor of model">
          <th scope="row">{{ getName(doctor) }}</th>
          <td>{{ getDoctorPosition(doctor) }}</td>
          <td>{{ getPhones(doctor) }}</td>
          <td>{{ getEmail(doctor) }}</td>
          <ng-container *ngIf="authService.hasRole('ROLE_CARE_COORDINATOR')">
            <td>
              <button
                pButton
                (click)="removeConsent(doctor.id)"
                class="p-button-danger p-button-text"
                icon="fas fa-times"
              ></button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <button pButton (click)="close()" [label]="'general.button.texts.Close' | translate"></button> -->
</div>
<p-dialog
  appendTo="body"
  *ngIf="isInvitePopupVisible"
  [header]="'Add to Care Team' | translate"
  [(visible)]="isInvitePopupVisible"
  [modal]="true"
  positionTop="100"
  [style]="{ width: '700px', 'max-width': '100%', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
>
  <div>
    <div>{{ "Click to select a practitioner to add to patient care team" | translate }}</div>
    <div class="p-1"></div>
    <table class="table hoverable">
      <thead>
        <tr>
          <th scope="col">Name</th>
        </tr>
      </thead>
      <tbody>
        <tr
          (click)="selectPractitioner(doctor.id)"
          [class.selected]="doctor.id === selectedDoctorId"
          *ngFor="let doctor of practitioners"
        >
          <td scope="row">{{ doctor.name }}</td>
        </tr>
      </tbody>
    </table>
    <div class="p-1"></div>
    <div class="separator"></div>
    <div class="p-1"></div>
    <button pButton (click)="savePractitionerToCareTeam()" [label]="'Add to Care Team' | translate"></button>
  </div>
</p-dialog>
