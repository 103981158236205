import { NgModule } from "@angular/core";
import { DefaultModules } from "../../../../services/app.constants";
import { PatientCareTeamComponent } from "./patient-care-team.component";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [PatientCareTeamComponent],
  imports: [...DefaultModules, ButtonModule, DialogModule, ReactiveFormsModule],
  exports: [PatientCareTeamComponent],
})
export class PatientCareTeamModule {}
