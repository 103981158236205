import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule, Route } from "@angular/router";
import { Role } from "../core/enum/Role";

export const DefaultModules = [CommonModule, TranslateModule, RouterModule];

export const availableLanguages = ["en", "es", "pt"];

// tslint:disable-next-line: class-name
export interface menuItem {
  routerLink?: string;
  iconClass?: any;
  text: string;
  sidebarMenuItem: boolean;
  tooltip?: string;
  roles?: Array<Role>;
  subMenuItems?: Array<menuItem>;
}

export const NotificationTypes = {
  dssRule: "DSS_RULE",
  userRule: "USER_RULE",
};

export enum SocketEvents {
  Connect = "connect",
  Disconnect = "disconnect",
  Authorize = "authorize",
  LogoutSockets = "logout-socket",
  NewChatMessage = "chat-message",
  Consent = "consent",
  Delivered = "delivered",
  PatientCriticalEvent = "patient-critical-event",
  NewPatientCreated = "patient-new-patient-created",
  ConsentAccepted = "doctor-consent-accepted",
  NotificationsChanged = "notifications-change",
  AppointmentsUpdated = "appointments-updated",
  AppointmentAccepted = "appointments-accepted",
}

export const ActionVariables = ["patientName", "patientEmail", "occuredDate", "ruleName"];
