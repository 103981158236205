import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from "@angular/core";
import { ThemeService } from "../theme.service";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NewsAnimatorModel } from "../news-animator/news-animator.component";
import { MenuItem } from "primeng/api";
import { AuthenticationService } from "src/app/services/authentication.service";
import { filter, map, mergeMap } from "rxjs/operators";
import { LanguageService } from "src/app/core/language.service";
import { INotification } from "src/app/models/notification.model";
import { NotificationsService } from "src/app/services/notifications.service";
import { Subscription } from "rxjs";
import { ToastService } from "src/app/core/toast.service";
import * as moment from "moment";
import { BreadcrumbService } from "../breadcrumb/breadcrumb.service";
import { BreadcrumbModel } from "../breadcrumb/breadcrumb.model";
import { Role } from "src/app/core/enum/Role";
import { AssistantService } from "../../../services/assistant.service";
import { ASSISTANT_ACTIONS } from "../../../core/enum/Assistant";
import { MessageBroadcasterService } from "../../../services/message.service";
import { IWindow, SpeechRecognitionService } from "../../../services/speech-recognition.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "iti-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          "padding-left": "250px",
        })
      ),
      state(
        "out",
        style({
          "padding-left": "0px",
        })
      ),
      transition("in => out", [animate("200ms ease-in")]),
      transition("out => in", [animate("200ms ease-in")]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("notificationsContainer") notificationsContainer!: ElementRef;
  @ViewChild("notificationsIcon") notificationsIcon!: ElementRef;
  @ViewChild("headerNewsLabel") headerNewsLabel!: ElementRef;
  @ViewChild("assistantContainer") assistantContainer!: ElementRef;
  @ViewChild("assistantIcon") assistantIcon!: ElementRef;
  @ViewChild("iconButton") iconButton!: ElementRef;
  @ViewChild("iconButton2") iconButton2!: ElementRef;
  pageTitle = "";
  breadcrumb!: any;
  isHeaderVisible = true;
  isNotificationPopupVisible = false;
  newsMessages: Array<NewsAnimatorModel> = [];
  notifications: Array<INotification> = [];
  notificationsCount = 0;
  accountMenuItems: Array<MenuItem> = [];
  username = "";
  notificationsSubscription!: Subscription;
  breadcrumbServiceSubscription!: Subscription;
  isAssistantPopupVisible = false;
  assistantHistory: Array<{ text: string; id: number; isAnswer: boolean; firstContinuousAnswer: boolean }> = [];
  patientRestrictedAccess!: boolean;
  command: string | null = null;
  disableSpeechControls = false;
  textToSpeechEnabled = false;
  isRecording = false;
  isRecordingSubscription!: Subscription;
  userRole = "";

  get selectedLang() {
    return this.translateService.currentLang;
  }

  @HostListener("document:click", ["$event"]) toggleOpen(event: Event) {
    this.isNotificationPopupVisible =
      this.notificationsIcon.nativeElement.contains(event.target) && this.notificationsCount > 0
        ? !this.isNotificationPopupVisible
        : this.notificationsContainer && this.notificationsContainer.nativeElement.contains(event.target)
        ? true
        : false;
    this.isAssistantPopupVisible = this.assistantIcon.nativeElement.contains(event.target)
      ? !this.isAssistantPopupVisible
      : this.assistantContainer && this.assistantContainer.nativeElement.contains(event.target)
      ? true
      : false;
  }

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private authService: AuthenticationService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private notificationsService: NotificationsService,
    private toastService: ToastService,
    private breadcrumbService: BreadcrumbService,
    private assistanceService: AssistantService,
    private messageService: MessageBroadcasterService,
    private speechRecognitionService: SpeechRecognitionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.breadcrumbServiceSubscription = this.breadcrumbService.onDataUpdated.subscribe((x: Array<BreadcrumbModel>) => {
      this.breadcrumb = x;
    });

    this.accountMenuItems = [
      {
        label: this.translateService.instant("general.logout"),
        routerLink: "/logout",
      },
    ];

    this.newsMessages = [];
    this.notifications = [];
    this.notificationsCount = 0;

    if (this.authService.getTokenModel()) {
      this.username = this.authService.getTokenModel().email.split("@")[0];
      this.userRole = this.authService.getUserRoles()?.[0];
    }

    this.patientRestrictedAccess = this.authService.hasRole(Role.Doctor);

    this.translateService.onLangChange.subscribe(() => {
      this.getReadNotifications();
    });

    this.getReadNotifications();

    this.notificationsSubscription = this.notificationsService.notifyNotificationsChanged().subscribe(() => {
      this.getReadNotifications();
    });

    this.messageService.patientCriticalEvent.subscribe((x) => {
      this.getReadNotifications();
    });

    this.isRecordingSubscription = this.speechRecognitionService.isRecording().subscribe((state) => {
      this.isRecording = state;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.notificationsSubscription.unsubscribe();
    this.breadcrumbServiceSubscription.unsubscribe();
    this.isRecordingSubscription.unsubscribe();
  }

  get isOpen() {
    return this.themeService.animationState;
  }

  pauseNewsAnimation() {
    this.headerNewsLabel.nativeElement.style["animation-play-state"] = "paused";
  }

  continueNewsAnimation() {
    this.headerNewsLabel.nativeElement.style["animation-play-state"] = "running";
  }

  subscribeNavigationEnd() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((data: any) => {
        try {
          this.pageTitle = data.title;
          window.scrollTo(0, 0);
        } catch (err) {}
      });
  }

  toggleNotifications() {
    this.isNotificationPopupVisible = !this.isNotificationPopupVisible;
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }

  toggleMenu() {
    this.themeService.toggleMenu();
  }

  getLanguageName(lang: string = "") {
    if (lang === "") {
      lang = this.translateService.currentLang;
    }

    let name = "English";
    switch (lang) {
      case "en":
        name = "English";
        break;
      case "el":
        name = "Ελληνικά";
        break;
      case "sv":
        name = "Svenska";
        break;
      case "it":
        name = "Italiano";
        break;
      case "pt":
        name = "Português";
        break;
      case "tr":
        name = "Türkçe";
        break;
      case "es":
        name = "Español";
        break;
    }

    return name;
  }

  getLanguageImage(lang: string = "") {
    if (lang === "") {
      lang = this.translateService.currentLang;
    }

    let position = "0";
    switch (lang) {
      case "en":
        position = "-2182px";
        break;
      case "el":
        position = "-2446px";
        break;
      case "sv":
        position = "-2446px";
        break;
    }

    return position;
  }

  changeLanguage(lang: string) {
    this.languageService.changeLanguage(lang);
  }

  markNotificationAsRead(id: number, type: string, notification: any) {
    this.notificationsService
      .markNotificationAsRead({ id, isCritical: notification.isCritical || false, isRead: true })
      .subscribe(() => {
        this.toastService.successMessage(this.translateService.instant(`headerPage.NotificationMarkedAsRead`));
        if (notification.NotificationType === "ADoctorRequestedASecondOpinion") {
          this.router.navigateByUrl(
            `patients/patient-record/${notification.data.patientFhirId}/careplan/current?itemId=${notification.data.careplanResourceItemId}`
          );
        }
      });
  }

  getReadNotifications() {
    this.notificationsService
      .getAllNotifications(undefined, undefined, undefined, undefined, undefined, undefined, false)
      .subscribe((notifications: any) => {
        this.notificationsCount = notifications.notifications?.filter((z: any) => !z.isFixed)?.length;
        this.notifications = notifications.notifications.slice(0, 5);
        this.notifications.forEach((x: any) => {
          x.createdStr = moment(x.createdAt).format("YYYY-MM-DD hh:mm");
        });
      });
  }

  navigateToNotifications() {
    this.toggleNotifications();
    this.router.navigate(["/notifications"]);
  }

  navigateToCriticalNotifications() {
    this.toggleNotifications();
    this.router.navigate(["/critical-notifications"]);
  }

  async executeCommand(text?: string) {
    this.addMessageToAssistant(text || this.command!);

    this.disableSpeechControls = true;
    try {
      const response = await this.assistanceService.getAnswerFromAgent(text || this.command!).toPromise();
      this.disableSpeechControls = false;

      if (Array.isArray(response)) {
        for (const answer of response) {
          if (answer.text) {
            this.addMessageToAssistant(answer.text, true);
            this.say(answer.text);
            this.command = null;
          }
        }
      } else if (response?.action === ASSISTANT_ACTIONS.printText) {
        this.addMessageToAssistant(response.text);
        this.say(response.text);
        this.command = null;
      } else if (response?.action === ASSISTANT_ACTIONS.navigate) {
        // this.navigateToView(response.text);
        this.say(response.text);
        this.command = null;
      } else {
        this.addMessageToAssistant(this.translateService.instant("assistant.COMMAND_NOT_RECOGNIZED"));
        this.command = null;
      }
    } catch (err) {
      this.disableSpeechControls = false;
    }
  }

  addMessageToAssistant(message: string, isAnswer = false) {
    let newID = this.assistantHistory ? this.assistantHistory.length + 1 : 0;
    let firstContinuousAnswer = false;
    if (
      isAnswer &&
      this.assistantHistory.length > 0 &&
      !this.assistantHistory[this.assistantHistory.length - 1].isAnswer
    ) {
      firstContinuousAnswer = true;
    }
    this.assistantHistory.push({ text: message, id: newID, isAnswer, firstContinuousAnswer });
  }

  markNotificationFixed(notification: any) {
    this.notificationsService
      .markNotificationAsFixed({
        id: notification.id,
        isCritical: notification.isCritical || false,
        isFixed: true,
      })
      .subscribe(() => {
        this.toastService.successMessage("toastFixed");
        this.getReadNotifications();
      });
  }

  toggleRecording() {
    if (!this.speechRecognitionService.isAvailable()) {
      this.speechRecognitionService.init(
        (text: string) => {
          this.executeCommand(text);
          this.speechRecognitionService.stop();
          return;
        },
        (error) => {
          console.log(error);
          return null;
        }
      );
    }

    if (!this.isRecording) {
      this.speechRecognitionService.start();
    } else {
      this.speechRecognitionService.stop();
    }
  }

  getMicColor() {
    return this.isRecording ? "#ffbebe" : "white";
  }

  getDisplay(num: number) {
    const result = this.textToSpeechEnabled ? (num === 1 ? "block" : "none") : num === 2 ? "block" : "none";
    console.log(num, result);
    return result;
  }

  toggleTextToSpeech() {
    this.textToSpeechEnabled = !this.textToSpeechEnabled;
    this.iconButton.nativeElement.style.display = this.getDisplay(1);
    this.iconButton2.nativeElement.style.display = this.getDisplay(2);
    if (!this.textToSpeechEnabled) speechSynthesis.cancel();
  }

  say(m: string) {
    try {
      if (this.textToSpeechEnabled) {
        const { SpeechSynthesisUtterance, speechSynthesis }: IWindow = window as IWindow;
        speechSynthesis.cancel();
        var msg = new SpeechSynthesisUtterance(m);
        var voices = window.speechSynthesis.getVoices();
        msg.voice = voices.find((x) => x.voiceURI === "Google UK English Male");
        msg.rate = 1;
        msg.pitch = 1.2;
        msg.text = m;
        msg.lang = "en-US";
        speechSynthesis.speak(msg);
      }
    } catch (error) {
      console.log(error);
    }
  }
}
