<div class="m-2"></div>
<div class="custom-tabs-container">
  <div class="custom-tab" [class.active]="activeTab === 'patient-file'" (click)="activateTab('patient-file')">
    {{ "Patient file" | translate }}
  </div>
  <div class="custom-tab" [class.active]="activeTab === 'general'" (click)="activateTab('general')">
    {{ "General" | translate }}
  </div>
</div>
<div class="m-2 separator"></div>
<ng-container *ngIf="activeTab === 'general'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <label class="bold">{{ "Select the main conditions of the patient" | translate }}</label>
        <div class="p-1"></div>
        <div *ngFor="let item of conditions" style="margin-bottom: 5px">
          <p-checkbox [binary]="true" [(ngModel)]="item.isSelected" [label]="item.label | translate"></p-checkbox>
        </div>
        <div class="p-1"></div>
        <div>
          <button pButton class="p-button-success" (click)="saveMainConditions()">
            {{ "Save main conditions" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="activeTab === 'patient-file'">
  <div class="medical-record row">
    <ng-container *ngFor="let record of records">
      <div class="col-md-12 col-lg-12 col-xl-4">
        <p-card>
          <div class="header d-flex">
            <label class="font-size-18">{{ record.title | translate }}</label>
            <span class="flex-grow-1"></span>
            <button
              *ngIf="record.canAdd"
              pButton
              [icon]="record.id === 'blood' || record.id === 'general' ? 'fas fa-edit' : 'fas fa-plus'"
              (click)="toggleEditPopup('add_' + record.id)"
              iconPos="left"
            ></button>
          </div>
          <div class="content">
            <div style="height: 100%; overflow: auto">
              <div class="m-2" style="padding-right: 10px">
                <div *ngIf="getFilteredRecordItems(record.items, record.id).length === 0">
                  <p class="italic text-center m-4" *ngIf="record.id === 'blood' || record.id === 'general'">
                    * {{ "medicalRecord.NoRecordCategoriesFound" | translate }}
                  </p>
                  <p class="italic text-center m-4" *ngIf="record.id !== 'blood' && record.id !== 'general'">
                    * {{ "medicalRecord.NoRecordsFound" | translate }}
                  </p>
                </div>
                <div
                  class="item"
                  [ngClass]="{ action: record.action, even: itemIndex % 2 == 0, odd: itemIndex % 2 == 1 }"
                  *ngFor="let item of getFilteredRecordItems(record.items, record.id); let itemIndex = index"
                  (click)="record.action && toggleInfoPopup(record.attrForAction, item.data)"
                >
                  <div>
                    <div
                      class="d-flex flex-column"
                      style="padding: 5px 15px; border-bottom: 1px solid #f1f1f1"
                      *ngIf="record.id === 'general' || record.id === 'blood' || record.id === 'body-measurements'"
                    >
                      <div class="d-flex flex-row justify-content-between align-items-center">
                        <div class="font-weight-bold">
                          {{ item.name | translate }} {{ item.unit ? "(" + item.unit + ")" : "" }}
                        </div>
                        <div class="">
                          <button
                            pButton
                            (click)="openObservationChart(item)"
                            *ngIf="item.value && item.value !== '-' && item.id !== 'age' && item.id !== 'gender'"
                            icon="far fa-chart-bar"
                            class="p-button-outlined p-button-info"
                            style="color: #00a89f !important; border: none"
                          ></button>
                          <button
                            pButton
                            (click)="openAddObservationPopup(item)"
                            *ngIf="canAddObservation[item.id]"
                            icon="pi pi-plus"
                            class="p-button-outlined"
                            style="color: #007ad9 !important; border: none"
                          ></button>
                          <!-- <button
                          pButton
                          (click)="removeCategory(item.id)"
                          *ngIf="record.id !== 'body-measurements'"
                          icon="pi pi-eye-slash"
                          class="p-button-info p-button-outlined"
                          style="border: none"
                        ></button> -->
                          <button
                            *ngIf="item.fhirId"
                            pButton
                            (click)="removeObservation(item.fhirId)"
                            icon="pi pi-times"
                            class="p-button-danger p-button-outlined"
                            style="border: none"
                          ></button>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-between">
                        <div class="mb-1">{{ "medicalRecord.value" | translate }}:</div>
                        <div class="mb-1">{{ item.value }} {{ item.unit }}</div>
                      </div>
                      <div class="d-flex flex-row justify-content-between">
                        <div>{{ "medicalRecord.lastUpdated" | translate }}:</div>
                        <div>{{ item.date | date : "dd/MM/yyyy HH:mm:ss" }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="item"
                    *ngIf="
                      record.id === 'allergies' ||
                      record.id === 'vaccines' ||
                      record.id === 'procedures' ||
                      record.id === 'medication' ||
                      record.id === 'social_history' ||
                      record.id === 'family_history' ||
                      record.id === 'conditions' ||
                      record.id === 'hospitalizations' ||
                      record.id === 'reports'
                    "
                  >
                    <div class="d-flex flex-column">
                      <div class="d-flex flex-row justify-content-between align-items-center">
                        <div class="bold italic wrap">{{ item.name | translate }}</div>
                        <div>
                          <button
                            pButton
                            (click)="editItem($event, record.id, item.data)"
                            icon="fas fa-pencil-alt"
                            class="p-button-outlined"
                            style="color: #007ad9 !important; border: none"
                          ></button>
                          <button
                            pButton
                            (click)="removeItem($event, record.id, item.id)"
                            icon="pi pi-times"
                            class="p-button-danger p-button-outlined"
                            style="border: none"
                          ></button>
                        </div>
                      </div>
                      <div class="col-12 wrap" *ngIf="item.dateStr">{{ item.dateStr }}</div>
                      <div class="col-12 wrap" *ngIf="item.period">
                        {{ item.period.start }}&nbsp;-&nbsp;{{ item.period.end }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-card>
        <br />
      </div>
    </ng-container>
    <ng-container>
      <div class="col-md-12 col-lg-12 col-xl-4">
        <p-card>
          <div class="header d-flex">
            <label class="font-size-18">{{ "Application scores" | translate }}</label>
          </div>
          <div class="content">
            <div style="height: 100%; overflow: auto">
              <div class="m-2" style="padding-right: 10px">
                <div
                  class="item"
                  [ngClass]="{ even: itemIndex % 2 == 0, odd: itemIndex % 2 == 1 }"
                  *ngFor="let item of applicationScores; let itemIndex = index"
                >
                  <div>
                    <div class="d-flex flex-column">
                      <div class="bold italic wrap d-flex">
                        <span class="flex-grow-1">
                          {{ item.item.name | translate }}
                        </span>
                        <div>
                          <button
                            pButton
                            (click)="openApplicationsScoreChart(item)"
                            icon="far fa-chart-bar"
                            class="p-button-outlined p-button-info"
                            style="color: #00a89f !important; border: none"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div class="wrap d-flex">
                      <div class="mb-1 flex-grow-1">{{ "medicalRecord.value" | translate }}:</div>
                      {{ item.points }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-card>
      </div>
    </ng-container>
  </div>
</ng-container>

<p-dialog
  appendTo="body"
  *ngIf="isAddObservationVisisble"
  [header]="'medicalRecord.addObservation' | translate"
  [(visible)]="isAddObservationVisisble"
  [modal]="true"
  [style]="{ width: '400px', 'max-width': '100%', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
>
  <div>
    <form [formGroup]="addObservationForm" novalidate (submit)="addNewObservation()">
      <div class="p-g">
        <div class="p-g-12 d-none">
          <label>{{ "medicalRecord.SelectObservation" | translate }}</label>
          <p-dropdown
            [options]="addObservationDropDownRecords"
            formControlName="observationCode"
            optionLabel="label"
            [placeholder]="'medicalRecord.SelectAnObservation' | translate"
          ></p-dropdown>
        </div>
        <div class="p-g-12">
          <label>{{ "medicalRecord.SetObservationValue" | translate }}</label>
          <input type="number" appOnlyNumber="true" pInputText formControlName="observationValue" />
        </div>
        <div class="p-g-12">
          <label>{{ "medicalRecord.SelectDateTime" | translate }}</label>
          <p-calendar formControlName="observationDate" [showTime]="true" [maxDate]="maxObservationDate"> </p-calendar>
        </div>
        <div class="p-1"></div>
        <div class="separator"></div>
        <div class="p-1"></div>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <button
            pButton
            type="submit"
            icon="pi pi-plus"
            [label]="'general.button.texts.save' | translate"
            class="p-button-rounded"
            [disabled]="formSubmitting"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="isInfoPopupVisible"
  [baseZIndex]="10000"
  [modal]="true"
  [style]="{ 'min-width': '60vw', 'max-width': '80vw', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  [resizable]="true"
  [focusOnShow]="false"
  positionTop="50"
>
  <app-info-popup
    *ngIf="showComponent === 'info'"
    [model]="selectedRecord"
    [attributes]="selectedAttributes"
    (closed)="isInfoPopupVisible = false"
  ></app-info-popup>
  <app-add-procedure
    *ngIf="showComponent === 'add_procedures'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveProcedure($event)"
  ></app-add-procedure>
  <app-add-medical-report
    *ngIf="showComponent === 'add_reports'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveReport($event)"
  ></app-add-medical-report>
  <app-add-vaccine
    *ngIf="showComponent === 'add_vaccines'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveVaccine($event)"
  ></app-add-vaccine>
  <app-add-family-member
    *ngIf="showComponent === 'add_family_history'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveFamilyMember($event)"
  ></app-add-family-member>
  <app-add-allergy
    *ngIf="showComponent === 'add_allergies'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveAllergy($event)"
  ></app-add-allergy>
  <app-add-medication
    *ngIf="showComponent === 'add_medication'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveMedication($event)"
  ></app-add-medication>
  <app-add-condition
    *ngIf="showComponent === 'add_conditions'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveCondition($event)"
  ></app-add-condition>
  <app-add-social-history
    *ngIf="showComponent === 'add_social_history'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveSocialHistory($event)"
  ></app-add-social-history>
  <app-add-encounter
    *ngIf="showComponent === 'add_hospitalizations'"
    (closed)="isInfoPopupVisible = false"
    (success)="saveHospitalization($event)"
  ></app-add-encounter>
  <div *ngIf="showComponent === 'add_general' || showComponent === 'add_blood'">
    <div class="d-flex flex-column">
      <label class="mb-3" style="font-size: 1.2rem">{{ "medicalRecord.SelectCategories" | translate }}</label>
      <div class="p-fluid mb-3">
        <p-multiSelect
          [options]="filteredExcludedCategories"
          [defaultLabel]="'general.Choose' | translate"
          [(ngModel)]="selectedCategories"
          optionLabel="label"
        >
        </p-multiSelect>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <button
          style="width: 20%"
          (click)="addCategories()"
          pButton
          class="p-button-rounded"
          [disabled]="formSubmitting"
          [label]="'general.button.texts.save' | translate"
        ></button>
        <!-- <button
          style="width: 20%"
          (click)="isInfoPopupVisible = false"
          pButton
          class="p-button-rounded p-button-danger"
          [disabled]="formSubmitting"
          [label]="'general.button.texts.Cancel' | translate"
        ></button> -->
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  [baseZIndex]="10000"
  *ngIf="showChartPopup"
  [(visible)]="showChartPopup"
  header="{{ chartInput.name | translate }}"
  [style]="{ width: '70vw', height: '90vh' }"
  positionTop="40"
  [draggable]="false"
  [resizable]="false"
  id="charts-dialog"
  [modal]="true"
  [blockScroll]="true"
>
  <app-observation-graph
    [model]="chartInput"
    [patientId]="patientId"
    [gender]="patientGender"
    (closed)="showChartPopup = false"
  >
  </app-observation-graph>
</p-dialog>

<p-dialog
  [baseZIndex]="10000"
  [(visible)]="isEditPopupVisible"
  [modal]="true"
  [style]="{ width: '600px', 'max-width': '100%', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  [resizable]="true"
  [focusOnShow]="false"
  positionTop="50"
>
  <app-add-procedure
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'procedures'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-procedure>
  <app-add-vaccine
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'vaccines'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-vaccine>
  <app-add-family-member
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'family_history'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-family-member>
  <app-add-allergy
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'allergies'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-allergy>
  <app-add-medication
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'medication'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-medication>
  <app-add-condition
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'conditions'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-condition>
  <app-add-medical-report
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'reports'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-medical-report>
  <app-add-encounter
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'hospitalizations'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-encounter>
  <app-add-social-history
    [model]="selectedItemForEdit"
    *ngIf="editComponentCategory === 'social_history'"
    (closed)="isEditPopupVisible = false"
    (success)="saveEditedItem($event)"
  ></app-add-social-history>
</p-dialog>

<p-dialog
  [baseZIndex]="10000"
  [(visible)]="showEditParticipationPopup"
  [modal]="true"
  [style]="{ width: '600px', 'max-width': '100%', height: 'auto' }"
  positionTop="50"
>
  <app-add-participation-info
    [model]="patientParticipationData"
    (closed)="showEditParticipationPopup = false"
    (success)="saveParticipationInfo($event)"
  ></app-add-participation-info>
</p-dialog>

<p-dialog
  [baseZIndex]="10000"
  [(visible)]="showApplicationsChart"
  [modal]="true"
  [style]="{ width: '700px', 'max-width': '100%', height: 'auto' }"
  positionTop="50"
>
  <div *ngIf="showApplicationsChart">
    <p-chart
      type="line"
      [data]="applicationsScoresDataSet"
      [options]="optionsLineChart"
      [height]="'65vh'"
      [width]="'100%'"
    ></p-chart>
  </div>
</p-dialog>
