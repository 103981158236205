import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import * as fhir from "fhir-stu3";

@Injectable({
  providedIn: "root"
})
export class CarePlanService {
  public carePLanChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  removeActivity(resourceType: string, id: string, patientId: string, careplanId: string) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/remove/${patientId}`, {
      resourceType,
      id,
      careplanId,
    });
  }

  removeActivityObservation(detailIndex: number, careplanId: string, patientId: string) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/remove-observation/${patientId}`, {
      detailIndex,
      careplanId,
    });
  }

  getPatientCarePlan(patientId: string) {
    return this.http.get<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/get-careplan/${patientId}`
    );
  }

  addCarePlanObservation(activityId: string, type: string, patientId: string) {
    return this.http.post<fhir.Observation>(
      `${environment.serverPath}/api/patient-record/careplan/activities/add-observation`,
      { activityId, type }
    );
  }

  getCarePlanObservations(activityId: string, type: string, patientId: string) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("activityId", activityId);
    searchParams = searchParams.append("type", type);

    return this.http.get<Array<fhir.Observation>>(
      `${environment.serverPath}/api/patient-record/careplan/activities/get-observations/${patientId}`,
      { params: searchParams }
    );
  }

  getCarePlanObservationsByType(patientId: string, observationCode: string, startDate: string, endDate: string) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("observation", observationCode);
    searchParams = searchParams.append("start", startDate);
    searchParams = searchParams.append("end", endDate);

    return this.http.get<Array<fhir.Observation>>(
      `${environment.serverPath}/api/patient-record/careplan/activities/get-observations-by-type/${patientId}`,
      { params: searchParams }
    );
  }

  getActivityObservations(activityId: string, type: string, patientId: string, startDate: string, endDate?: string|null) {
    return this.http.post<Array<fhir.Observation>>(
      `${environment.serverPath}/api/patient-record/careplan/activities/get-observations-period/${patientId}`,
      { activityId, type, startDate, endDate }
    );
  }

  addNutritionOrderObservation(activityId: string, type: string, data: any, effectiveDateTime: string) {
    return this.http.post<fhir.Observation>(
      `${environment.serverPath}/api/patient-record/careplan/activities/add-nutrition-observation`,
      { activityId, type, data, effectiveDateTime }
    );
  }

  updateNutritionOrderObservation(activityId: string, type: string, data: any) {
    return this.http.put<fhir.Observation>(
      `${environment.serverPath}/api/patient-record/careplan/activities/update-nutrition-observation`,
      { activityId, type, data }
    );
  }

  getAllInOneForPeriod(
    patientId: string,
    startDateActivity: string,
    endDateActivity: string,
    resourceType: string,
    activityId?: string,
    observationCode?: string,
    questionnaireTiming?: string,
    period?: number,
    periodUnit?: string,
    frequency?: number
  ) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("startDateActivity", startDateActivity);
    searchParams = searchParams.append("endDateActivity", endDateActivity);
    searchParams = searchParams.append("resourceType", resourceType);
    if (activityId) searchParams = searchParams.append("activityId", activityId);
    if (observationCode) searchParams = searchParams.append("observationCode", observationCode);
    if (questionnaireTiming) searchParams = searchParams.append("questionnaireTiming", questionnaireTiming.toString());
    if (period) searchParams = searchParams.append("period", period.toString());
    if (periodUnit) searchParams = searchParams.append("periodUnit", periodUnit);
    if (frequency) searchParams = searchParams.append("frequency", frequency.toString());

    return this.http.get<any>(`${environment.serverPath}/api/activities/${patientId}`, {
      params: searchParams,
    });
  }

  getPatientDoctors(patientFhirId: string) {
    return this.http.get<fhir.Practitioner[]>(
      `${environment.serverPath}/api/doctors/get-patient-consented-doctors/${patientFhirId}`
    );
  }

  updateExercise(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(`${environment.serverPath}/api/patient-record/careplan/exercise/${patientId}`, {
      model,
    });
  }

  updateDrug(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(`${environment.serverPath}/api/patient-record/careplan/drug/${patientId}`, {
      model,
    });
  }

  updateNutrition(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/nutrition/${patientId}`,
      {
        model,
      }
    );
  }

  updateEducation(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/education/${patientId}`,
      {
        model,
      }
    );
  }

  updateLaboratory(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/laboratory/${patientId}`,
      {
        model,
      }
    );
  }

  updateAppointment(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/appointment/${patientId}`,
      {
        model,
      }
    );
  }

  updateObservation(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/observation/${patientId}`,
      {
        model,
      }
    );
  }

  updateQuestionnaire(patientId: string, model: any) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/questionnaire/${patientId}`,
      {
        model,
      }
    );
  }

  addFileToLaboratoryActivity(patientId: string, activityId: any, path: string) {
    return this.http.put<fhir.CarePlan>(
      `${environment.serverPath}/api/patient-record/careplan/laboratory/upload/${patientId}`,
      {
        activityId,
        path,
      }
    );
  }
}
